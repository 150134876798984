// src/StyledComponents.js
import styled, { keyframes }  from 'styled-components';


const mobileBreakpoint = '768px';

export const UnicodeIcon = styled.span`
  font-family:inherit;
`;

export const IconLogo = ()=>{
  return (
      <UnicodeIcon>🌼</UnicodeIcon>
  )
};

export const IconSquare = ()=>{
  return (
      <UnicodeIcon>▪</UnicodeIcon>
  )
};


export const Navbar = styled.nav`
  background-color: var(--yellow-300);
  z-index:999;
  position: relative;
  padding: 1em;
  display: flex;
  align-items: center;



  h1 {
    color:black;
    font-size:1.3em;
  }

  h1 a {
    color:black;
    font-family:"Header";
  }

`;

export const HiddenMobile = styled.span`

 @media (max-width: ${mobileBreakpoint}) {
    display: none; // hide the component on mobile screens
  }
`;

export const ShowMobile = styled.span`

 @media (min-width: ${mobileBreakpoint}) {
    display: none; // hide the component on mobile screens
  }
`;

export const NavbarLinks = styled.span`
  display: flex; // To layout the links horizontally
  margin-left: auto; // Push the span to the far right
  color: #777;


  a {
    font-size: 0.75em;
    font-weight:bold;
    font-family: "Header";
    color: black;
    text-decoration: none;
    margin-left: 0.75em;
    margin-right: 0.75em;
    text-transform:uppercase;
  }

  a:hover {
    text-decoration: underline;
  }

`;


export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // Center the columns if they are less than the container's width
`;

export const Break = styled.div`
  height: 1em;
`;

export const Section = styled.div`
  background-color: ${props => props.bg || '#ccc'};
  position: relative;
  font-size: 1.25em;

  h2 {
    font-size:1.5em;
  }
`;

export const HeroContainer = styled.div`
    padding: 5em 0;
    min-height: fit-content;
    max-height: 3500px;
    display: flex; /* Enables Flexbox */
    flex-direction: row; /* Aligns children in a row */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */

    flex-wrap: wrap; /* Allows items to wrap onto multiple lines */
`

export const FixedHeightContainer = styled.div`
    height: ${props => props.height || '90vh'};
    min-height: fit-content;
    max-height: 3500px;
    display: flex; /* Enables Flexbox */
    flex-direction: row; /* Aligns children in a row */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */

    flex-wrap: wrap; /* Allows items to wrap onto multiple lines */
`;

export const Column = styled.div`
  width:40vw;
  margin: 0 1em;
`;


export const ColumnHeroImage = styled.div`
  width:40vw;
  max-width:400px;
  margin: 0 1em;

  @media (max-width: ${mobileBreakpoint}) {
     //width:100vw;
     display: none;
  }
`;

export const ColumnHeroDesc = styled.div`
  width:40vw;
  max-width:800px;
  margin: 0 1em;

  @media (max-width: ${mobileBreakpoint}) {
     width:100vw;
  }
`;


export const ColumnThirds = styled.div`
  width:25vw;
  margin: 0 1em;
  color: black;

  h2 {
    color: black;
    border-bottom: 1px solid black;
    padding: 0.5em 0;
    font-size:1em;
  }

   @media (max-width: ${mobileBreakpoint}) {
     width:100vw;
  }

`;

export const FAQSection = styled.div`
  width: 90%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 3em 0 1em 0;

  @media (max-width: ${mobileBreakpoint}) {
     width:90vw;
  }

  h1 {
    color: black;
    font-size:1.5em;
    padding-bottom: 0.25em;
    border-bottom: 1px solid black;
  }

`;

export const Button = styled.button`
  background-color:yellow;
  color: black;
  border: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  margin-right:5px;

  font-family:"Header";

    &:hover {
      // Add your hover styles here
      background-color: var(--mars-300); // Replace with the desired background color
      color: white; // Replace with the desired text color
       cursor: pointer; 
    }

    &:disabled {
      color: #333; // Replace with the desired text color
      background-color: var(--camo-300);
       cursor: not-allowed;
    }

`;



export const FAQQuestion = styled.div`
  color: black;
  margin: 3em 0;

  h2 {
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1em;
  }

  h2 {
    color: black;
    padding: 0.5em 0;
    font-size:1em;
  }

  a {
    color: var(--mars-300);
  }

`;


export const ResponsiveImage = styled.img`
  width: 100%; // Make image take full width of the container
  height: auto; // Keep image aspect ratio
  //max-width: 500px; // Maximum width of the image
/*
  // Add a media query for larger screens
  @media (min-width: 768px) {
    max-width: 100%; // On larger screens, let the image take full width
  }*/
`;


export const ErrorMessage = styled.div`
  background-color:red;
  font-size: 0.6em;
  padding: 0.75em;
  margin-top: 0.5em;
  border: 1px solid yellow;
  word-break: break-word;
`;

export const SuccessMessage = styled.div`
  background-color:var(--lime-300);
  color:black;
  font-size: 0.75em;
  padding: 0.75em;
  margin-top: 0.5em;
  border: 1px solid yellow;
  word-break: break-word;

  a {
    color: blue;
  }
`;
