import { mainnet, goerli } from 'wagmi/chains'
import { Chain } from 'wagmi'

// https://docs.blast.io/building/network-information
// https://0.12.x.wagmi.sh/react/chains
export const blastChain = {
  id: 81457,
  name: 'Blast',
  network: 'blast',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://blast.blockpi.network/v1/rpc/public'] },
    default: { http: ['https://blast.blockpi.network/v1/rpc/public'] },
  },
  blockExplorers: {
    etherscan: { name: 'Blast Explorer', url: 'https://blastscan.io' },
    default: { name: 'Blast Explorer', url: 'https://blastscan.io' },
  }
} 


export const blastTestnetChain = {
  id: 168587773,
  name: 'Blast Sepolia',
  network: 'blastsepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.ankr.com/blast_testnet_sepolia/13bc3e5c2b4ca20f87bf24426fde9530951c2ee4b658c542f919396d3ffb90f1'] },
    default: { http: ['https://rpc.ankr.com/blast_testnet_sepolia/13bc3e5c2b4ca20f87bf24426fde9530951c2ee4b658c542f919396d3ffb90f1'] },
  },
  blockExplorers: {
    etherscan: { name: 'Blast Explorer', url: 'https://testnet.blastscan.io' },
    default: { name: 'Blast Explorer', url: 'https://testnet.blastscan.io' },
  }
} 

export const chain = blastChain; // <-- MAINNET SET LIVE
// export const chain = blastTestnetChain; // testnet

export const getEtherscanAddressLink = (hash) => {
  return `${chain==blastTestnetChain ? 'https://testnet.blastscan.io/address/' : 'https://blastscan.io/address/'}${hash}`;
}

export const getEtherscanTxnLink = (hash) => {
  return `${chain==blastTestnetChain ? 'https://testnet.blastscan.io/tx/' : 'https://blastscan.io/txn/'}${hash}`;
}

export const getEtherscanContractLink = (hash) => {
  return `${chain==blastTestnetChain ? 'https://testnet.blastscan.io/address/' : 'https://blastscan.io/address/'}${hash}`;
}

console.log(`Chain: ${chain.name} (${chain.id})`)
console.log(`FrontEnd: ${getEtherscanContractLink(process.env.REACT_APP_CONTRACT_FRONTEND)}`)


export const links = {
  twitter: "https://twitter.com/modular_punks",
  contractFrontEnd: getEtherscanContractLink(process.env.REACT_APP_CONTRACT_FRONTEND),
  contractPunks: getEtherscanContractLink(process.env.REACT_APP_CONTRACT_PUNKS),
  contractTraits: getEtherscanContractLink(process.env.REACT_APP_CONTRACT_TRAITS),
  blast: "https://blast.io/",
};
