import * as Style from "../style/StyledComponents";
import { chain, getEtherscanAddressLink, getEtherscanTxnLink, getEtherscanContractLink, links } from "./Config";
import {useState, useEffect} from 'react'

import { InjectedConnector } from 'wagmi/connectors/injected'
import { useConnect , useAccount, useDisconnect } from 'wagmi'
import { usePrepareContractWrite, useContractWrite , useContractReads  } from 'wagmi'
import { waitForTransaction } from '@wagmi/core'

import { injected } from 'wagmi/connectors'
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';

BigNumber.config({ EXPONENTIAL_AT: 50 })

const contractABI_MintFrontEnd = require("../abi/MintFrontend.json");

const frontendContract = {
  address: process.env.REACT_APP_CONTRACT_FRONTEND,
  abi: contractABI_MintFrontEnd,
}


export function abbreviateAddress(address: string, charsLength: number = 4): string {
  if (typeof address !== 'string') return '';
  if (address.length < charsLength * 2 + 2) return address;

  const prefix = address.substr(0, charsLength + 2); // '0x' + charsLength characters
  const suffix = address.substr(-charsLength);

  return `${prefix}...${suffix}`;
}

export const Mint = () => {

  const { isConnected , address } = useAccount()
  const { disconnect } = useDisconnect()
  const { connectors, connect } = useConnect({
    connector: new InjectedConnector({chains:[chain]}),
    chainId: chain.id,
  });

  const [currentSupply, setCurrentSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(9000);
  const [mintPrice, setMintPrice] = useState("0.00000000001");
  const [mintCount, setMintCount] = useState(1);
  const [mintActive, setMintActive] = useState(false);
  const [maxMintAmount, setMaxMintAmount] = useState(1);

  // calculated
  const [totalMintPrice, setTotalMintPrice] = useState("0.0");

  const { data:readData, isError:readError, isLoading:readIsLoading } = useContractReads({
    enabled:true,
    contracts: [
      {
        ...frontendContract,
        functionName: 'currentSupply',
      },
      {
        ...frontendContract,
        functionName: 'maxSupply',
      },
      {
        ...frontendContract,
        functionName: 'maxMintAmount',
      },
      {
        ...frontendContract,
        functionName: 'mintActive',
      },
      {
        ...frontendContract,
        functionName: 'mintPrice',
      }
    ],
  });


  useEffect(()=>{
    if (readData != null) {
      console.log("Contract read:", readData);

      setCurrentSupply( parseInt(readData[0].result));
      setMaxSupply( parseInt(readData[1].result));
      setMaxMintAmount( parseInt(readData[2].result));
      setMintActive(readData[3].result);


      const price = new BigNumber(parseInt(readData[4].result));
      const total = price.multipliedBy("1E-18");
      setMintPrice(total.toString());

      //setMintPrice( parseInt(readData[4].result)); // todo

    }

  }, readData);


  const { data, isLoading, isSuccess, error, isError, write, reset } = useContractWrite({
    address: process.env.REACT_APP_CONTRACT_FRONTEND,
    abi: contractABI_MintFrontEnd,
    functionName: 'mint',
    chainId: chain.id,
    account: address,

    onSettled(data, error) {
      console.log('Transaction Settled', { data, error })
    },
  });

  useEffect(()=>{
    const price = new BigNumber(mintPrice);
    const total = price.multipliedBy(mintCount);
    setTotalMintPrice(total.toString());
  },  [mintPrice, mintCount]);


  function onClickPlus() {
    setMintCount(Math.min(maxMintAmount, mintCount+1));
  }

  function onClickMinus() {
    setMintCount(Math.max(1, mintCount-1));
  }

  function onClickConnect() {
    console.log("CONNECT");
    connect();
  }

  function onClickDisconnect() {
    console.log("Disconnect");
    disconnect();
    reset();
  }

  async function onClickMint() {
    reset();

    console.log(`Minting: mint(${mintCount}) with payable ${totalMintPrice} ETH`);
    write({
          args: [mintCount],
          from: address,
          value: ethers.parseEther(totalMintPrice),
          gas: new BigNumber(1000000 * mintCount),
    });
  }

  return (
    <div>


    {!isConnected && <><Style.Button onClick={onClickConnect}>Connect Wallet</Style.Button><p style={{fontSize:"0.75em", marginTop:"5px"}}>(Network: {chain.name})</p></>}


    {isConnected && !mintActive && <Style.Button disabled={true}>Minting not available</Style.Button>}
    {isConnected && mintActive && currentSupply == maxSupply && <Style.Button disabled={true}>Minted Out</Style.Button>}

    {isConnected && mintActive && currentSupply < maxSupply && 
      <div style={{display:"flex", flexDirection:"row"}}>
        <Style.Button disabled={isLoading} hidden={mintCount<=1} onClick={onClickMinus}>-</Style.Button>
        <Style.Button disabled={isLoading} onClick={onClickMint}>Mint {mintCount} Set{mintCount>1?"s":""}</Style.Button>
        <Style.Button disabled={isLoading} hidden={mintCount>=maxMintAmount || (currentSupply+mintCount+1 > maxSupply) } onClick={onClickPlus}>+</Style.Button>

        <div style={{marginLeft:"0.75em"}}>
          <span style={{fontSize:"0.9em", fontFamily:"Header", color: "var(--white)"}}>Total:<br/><span style={{fontSize:"1.5em", color: "var(--yellow-300)"}}>{totalMintPrice} Ξ</span></span><br/><span style={{fontSize:"0.75em", color: "var(--gray-600)"}}>(<strong>{mintCount} set{mintCount>1?"s":""}</strong> x {mintPrice} Ξ each)</span></div>
        </div>}


    {isConnected && 
      <div style={{marginTop:"0.5em", fontSize:"0.75em"}}>
        + Total Minted: {currentSupply} / {maxSupply}
        <br/>
         + Mint Price (Each): {mintPrice} Ξ
        <br/>
        + Mint Status: {mintActive?"Active":"Closed"}
        <br/>
        + Max Mints Per Txn: {maxMintAmount}
      </div>
    }

    {isConnected && <div style={{marginTop:"1.5em", fontSize:"0.7em"}}>🟢 Connected: <a href={getEtherscanAddressLink(address)} target="_blank">{abbreviateAddress(address)}</a> (<a onClick={onClickDisconnect}>Disconnect</a>)</div>}

    {isError && <Style.ErrorMessage>Error: {error.message}</Style.ErrorMessage>}

    {isSuccess && data != null && <Style.SuccessMessage>Transaction Sent:<br/>→ <a href={getEtherscanTxnLink(data.hash)} target="_blank">{data.hash}</a></Style.SuccessMessage>}

    </div>
  )
}
