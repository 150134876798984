import * as Style from "../style/StyledComponents";
import Navbar from "./Navbar";
import {Mint} from "./Mint";
import { chain, links } from "./Config";

import imgHeroAnim from "../images/hero.gif";
import imgHero from "../images/punklogo.png";
import imgDescMint from "../images/frontpage/mint-traits.png";
import imgDescTrade from "../images/frontpage/trade-traits.png";
import imgDescBuild from "../images/frontpage/build-a-punk.png";

function SectionHero() {
	return (
         <Style.Section bg="var(--camo-700)">
      		<Style.HeroContainer >	

      			<Style.ColumnHeroImage>
      				<div style={{margin:"0 auto"}}>
      			    <Style.ResponsiveImage style={{borderRadius:"25%"}}src={imgHeroAnim} />
      			    </div>
      			</Style.ColumnHeroImage>

      			<Style.ColumnHeroDesc>
      			   <h2>Build a Punk</h2>
      			   <br/>
      				<p>Modular Punks is an experimental, fully on-chain project that separates traits from their NFTs, allowing you to buy, sell, combine, and remove traits in any way you want.</p>

      				<br/>
              <br/>
              <Mint/>
      				
              <br/>
              <p>Each mint provides you with a set of 5 Modular Punks Trait NFTs (1 base type, 4 traits).</p>
              <br/>

      				<div style={{borderBottom:"1px solid white", fontFamily:"Header", padding:"0.25em 0", marginBottom:"0.5em", color:"var(--mars-300)"}}>Collection Details</div>
      				<p style={{fontSize:"0.75em"}}>
          + Supply: 10,000 PUNKS<br/>
					+ Mint Price: 0.001 Ξ<br/>
					+ Mint Date: Feb 29, 2024<br/>
          + Token Type: ERC-721<br/>
          + Provenance: First on-chain collection on Blast<br/>
      		+ Network: <a href={links.blast} target="_blank">{chain.name}</a><br/>
					+ <a href={links.contractFrontEnd} target="_blank">View Contract</a>
					</p>
      			</Style.ColumnHeroDesc>
      		</Style.HeroContainer>
       
         </Style.Section>)
}

function SectionDescription() {
	return (
         <Style.Section bg="var(--yellow-100)">

         	<Style.Container style={{padding:"4em 0"}}>
      			<Style.ColumnThirds>
      				<Style.ResponsiveImage src={imgDescMint} />
      			   <h2><span style={{fontSize:"0.75em", marginRight:"0.5em"}}>1.</span> Mint Traits</h2>
      			   <br/>
      				<p>With every mint, you will receive five Modular Punk Trait NFTs. Among these, one will be a distinct Punk Type (Ape, Female Dark, Male Albino, etc.), while the remaining four consist of a mix of randomly selected traits.</p>
      			</Style.ColumnThirds>

      			<Style.ColumnThirds>
      				<Style.ResponsiveImage src={imgDescTrade} />
      			   <h2><span style={{fontSize:"0.75em", marginRight:"0.5em"}}>2.</span> Trade Traits</h2>
      			   <br/>
      				<p>You may buy and sell these traits independently of each other. Keep what you like, sell what you don't, or buy ones you do.</p>
      			</Style.ColumnThirds>

      			<Style.ColumnThirds>
      				<Style.ResponsiveImage src={imgDescBuild} />
      			   <h2><span style={{fontSize:"0.75em", marginRight:"0.5em"}}>3.</span> Build a Punk</h2>
      			   <br/>
      				<p>Build a punk by merging several traits and a punk type into a Modular Punk. Add and remove traits to suit your taste.</p>
      			</Style.ColumnThirds>
       		</Style.Container>
         </Style.Section>)
}

function SectionFaq() {
	return (
         <Style.Section bg="var(--white)">

         	<Style.FAQSection>
       		<h1>FAQ</h1>

       		<Style.FAQQuestion>
       			<h2>Why Blast?</h2>
       			<p>Using an L2 like Blast enables unique functionality like adding and removing traits from Modular Punks without paying high gas fees. </p>
       			<p>Blast was the L2 of choice because of the ability to trade on Blur marketplace. </p>
       		</Style.FAQQuestion>

       		<Style.FAQQuestion>
       			<h2>Are Modular Punks stored onchain?</h2>
       			<p>Yes. All the art for Modular Punks is stored within the contracts and rendered as SVGs. The art will remain. </p>
       			<p>Future projects could reuse the assets and extend the trait set and renderer if they chose to do so. </p>
       		</Style.FAQQuestion>

       		<Style.FAQQuestion>
       			<h2>Is there a roadmap for Modular Punks?</h2>
       			<p>No roadmap. Just an experiment in art and NFT mechanics. Though it is possible to extend the Modular Punk trait set in the future, which could add never before seen traits.</p>
       		</Style.FAQQuestion>

       		<Style.FAQQuestion>
       			<h2>How do I build my punk?</h2>
       			<p>Each mint will create five Modular Punk Trait NFTs. One Punk Type (Female Dark, Male Albino, Ape, etc.) and four other random traits (Beanie, Muttonchops, Mole, etc.). You can then buy and sell these traits on the market, or combine 1 Punk Type trait with other traits to create a Modular Punk.</p>
       		</Style.FAQQuestion>

			<Style.FAQQuestion>
       			<h2>Are there restrictions on what traits I can match?</h2>
       			<p>Each Modular Punk Trait corresponds to a slot. The slots are Type, Background, Earring, Face, Head, Eyes, Mouth, Neck, Chin, Cigarette, and Nose. A punk must have the Type slot filled, and may only have 1 trait in each slot. </p>
				<p>Some traits may only be attached to certain genders. Apes and Alien punks are restricted from having Face, Mouth, and Nose traits.</p>
       		</Style.FAQQuestion>

       		<Style.FAQQuestion>
       			<h2>Project links</h2>
       			<p>
       				Contract: <a href={links.contractFrontEnd}>Front End</a> ({process.env.REACT_APP_CONTRACT_FRONTEND})<br/>
              Contract: <a href={links.contractPunks}>Modular Punks</a> ({process.env.REACT_APP_CONTRACT_PUNKS})<br/>
              Contract: <a href={links.contractTraits}>Traits</a> ({process.env.REACT_APP_CONTRACT_TRAITS})<br/>
       			</p>
       		</Style.FAQQuestion>

          <Style.FAQQuestion>
            <h2>How can I stay up to date on Modular Punks?</h2>
            <p>You can follow us at <a href={links.twitter} target="_blank">@modular_punks</a>.</p>
          </Style.FAQQuestion>

       		</Style.FAQSection>
         </Style.Section>)
}

function SectionTagline() {
	return (
         <Style.Section bg="var(--mars-300)">
         	<div style={{fontSize:"1.5em", textAlign:"center", padding:"1.5em 0"}}>
      		<p>First on-chain PFP collection on <a href={links.blast} target="_blank">Blast</a></p>
      		</div>
       
         </Style.Section>)
}

function FrontPage() {
  return (
      <>
        <Navbar/>
        <SectionHero/>
        <SectionTagline/>
        <SectionDescription/>
        <SectionFaq/>

      </>
  );
}

export default FrontPage;
