// src/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    //font-family: monospace, sans-serif;
    font-family: 'Paragraph', monospace, sans-serif;
    font-size: 1em;
    line-height:1.2em;
    margin: 0;
    padding: 0;
  }

  :root {
    --gray-900: #2d2d2d;
    --camo-200: #c3d4a5;
    --yellow-100: #fcfdc7;
    --gray-700: #646464;
    --black: black;
    --camo-300: #9ba885;
    --yellow-300: #fcfc03;
    --camo-700: #11140c;
    --camo-400: #75835d;
    --gray-975: #0d0d0d;
    --lime-300: #98dd28;
    --mars-300: #ed4e1d;
    --gray-400: #c9c9c9;
    --camo-500: #404833;
    --white: white;
    --gray-800: #474747;
    --gray-600: #8b8b8b;
    --gray-500: #a8a8a8;
    --gray-300: #d9d9d9;
    --gray-200: #e1e1e1;
    --camo-600: #252b1b;
    --yellow-600: #7b7638;
    --mars-400: #c53b10;
  }

  body {
    background-color: var(--black);
    color: white;
  }

  h1, h2, h3, h4 {
    font-family: 'Header', sans-serif;
    color: var(--yellow-300);
  }

  a {
    color: yellow;
    text-decoration:none;
  }

  a:hover {
    text-decoration:underline;
  }
`;

export default GlobalStyle;
